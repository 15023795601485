<template>
  <dashboard-content>
    <template v-slot:actions>
      <quota-usage
        v-if="currentHeadlineContentPack"
        :current="currentHeadlineContentPack.used"
        :limit="currentHeadlineContentPack.limit"
        :title="$t('sentence.used_credit')"
      />
    </template>

    <div class="mb-4">
      <h2>{{ $t('page.title_generator.page-title') }}</h2>
      <p>{{ $t('page.title_generator.page-subtitle') }}</p>
    </div>

    <idea-generator-form />
  </dashboard-content>
</template>

<script lang="ts">
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { defineComponent } from 'vue'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import IdeaGeneratorForm from '@/components/posts/idea-generator/IdeaGeneratorForm.vue'

export default defineComponent({
  components: { DashboardContent, QuotaUsage, IdeaGeneratorForm },
  setup () {

    const {
      currentHeadlineContentPack
    } = useContentPack()

    return {
      currentHeadlineContentPack,
    }
  }
})
</script>

<style scoped>
  button i {
    line-height: 1.1rem;
  }
</style>