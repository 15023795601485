
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { defineComponent } from 'vue'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import IdeaGeneratorForm from '@/components/posts/idea-generator/IdeaGeneratorForm.vue'

export default defineComponent({
  components: { DashboardContent, QuotaUsage, IdeaGeneratorForm },
  setup () {

    const {
      currentHeadlineContentPack
    } = useContentPack()

    return {
      currentHeadlineContentPack,
    }
  }
})
